<template>
  <div class="mb-3 card">
    <div>
      <v-tabs v-model="tab">
        <v-tab key="list">
          {{ $t('erp.lang_extraTabsZwaenge') }}
        </v-tab>
        <v-tab key="add">{{ $t('erp.lang_addExtraTab') }}</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">
            <!-- Table -->
            <Datatable v-show="showTable" ref="extraTabDatatable"
                       :api-endpoint="ENDPOINTS.DATATABLES.ERP.EXTRATAB"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="ExtraTabs"
                       @editEntry="entryEdit"
                       @deleteEntry="entryDelete"
                       @deleteEntries="entriesDelete"
                       show-delete-buttons
                       show-edit-buttons
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('garnishTabs')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('garnishTabs')"
            />
            <v-container v-if="showUpdate" fluid>
              <v-row>
                <v-col cols="12" sm="6" md="6" mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="text"
                                :label="$t('erp.lang_tabname')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="position"
                                :label="$t('generic.lang_sorting')"
                                autocomplete="off"
                                type="number"
                                step="1"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="minChoose"
                                :label="$t('settings.lang_minAuswahl')"
                                autocomplete="off"
                                type="number"
                                step="1"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="maxChoose"
                                :label="$t('settings.lang_maxAuswahl')"
                                autocomplete="off"
                                type="number"
                                step="1"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" >
                  <span class="font-weight-medium">{{ $t('erp.lang_warecreate_color') }}:</span>
                  <swatches
                      v-model="color" inline
                      background-color="transparent"
                  ></swatches>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="extraLayoutImage"
                      autocomplete="off"
                      :label="$t('erp.lang_settingExtraLayoutImage')"
                      required
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="showInPOS"
                      autocomplete="off"
                      :label="$t('generic.lang_displayInPOS')"
                      required
                  ></v-checkbox>
                </v-col>


                <v-col v-if="$store.getters['permissions/checkModule'](54)" cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="showInTableBee"
                      autocomplete="off"
                      :label="$t('generic.lang_displayInTablebee')"
                      required
                  ></v-checkbox>
                </v-col>


                <v-col v-if="$store.getters['permissions/checkModule'](13)" cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="showInLocalBee"
                      autocomplete="off"
                      :label="$t('generic.lang_displayInLocalbee')"
                      required
                  ></v-checkbox>
                </v-col>

                <v-col v-if="$store.getters['permissions/checkModule'](67)" cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="showInKiosk"
                      autocomplete="off"
                      :label="$t('generic.lang_displayInKiosk')"
                      required
                  ></v-checkbox>
                </v-col>

                <v-col v-if="$store.getters['permissions/checkModule'](67)" cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="showImagesInKiosk"
                      autocomplete="off"
                      :label="$t('erp.lang_showGarnishImageInKiosk')"
                      required
                  ></v-checkbox>
                </v-col>
              </v-row>


              <!-- edit -->
              <v-row>
                <v-col cols="12">
                  <h6>{{ $t('erp.lang_webTranslations') }}:</h6>
                </v-col>
                <v-col class="pt-0 pb-0" col="12" md="6">
                  <v-text-field @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="text_de" outlined
                                :label="$t('generic.lang_germanyName')">
                    <template v-slot:append>
                      <country-flag class="flag" country="DE"/>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" col="12" md="6">
                  <v-text-field @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="text_en" outlined
                                :label="$t('generic.lang_englishName')">
                    <template v-slot:append>
                      <country-flag class="flag" country="GB"/>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" col="12" md="6">
                  <v-text-field @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="text_fr" outlined
                                :label="$t('generic.lang_frenchName')">
                    <template v-slot:append>
                      <country-flag class="flag" country="FR"/>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" col="12" md="6">
                  <v-text-field @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="text_ar" outlined
                                :label="$t('generic.lang_arabicName')">
                    <template v-slot:append>
                      <country-flag class="flag" country="SA"/>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>


              <v-layout>
                <v-flex class="text-right">
                  <v-btn text color="error" @click="resetData">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn color="error" @click="deleteData">{{ $t('generic.lang_delete') }}</v-btn>
                  <v-btn color="success" :disabled="text.length < 1" @click="updateData">{{
                      $t('generic.lang_edit')
                    }}
                  </v-btn>
                </v-flex>
              </v-layout>

            </v-container>
          </v-tab-item>
          <v-tab-item key="add">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="6" mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="addTabName"
                                :label="$t('erp.lang_tabname')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="addTabPosition"
                                :label="$t('generic.lang_sorting')"
                                autocomplete="off"
                                type="number"
                                step="1"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="addTabMinChoose"
                                :label="$t('settings.lang_minAuswahl')"
                                autocomplete="off"
                                type="number"
                                step="1"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="addTabMaxChoose"
                                :label="$t('settings.lang_maxAuswahl')"
                                autocomplete="off"
                                type="number"
                                step="1"
                                required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" >
                  <span class="font-weight-medium">{{ $t('erp.lang_warecreate_color') }}:</span>
                  <swatches
                      v-model="color" inline
                      background-color="transparent"
                  ></swatches>
                </v-col>

              </v-row>


              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="addTabExtraLayoutImage"
                      autocomplete="off"
                      :label="$t('erp.lang_settingExtraLayoutImage')"
                      required
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="AddShowInPOS"
                      autocomplete="off"
                      :label="$t('generic.lang_displayInPOS')"
                      required
                  ></v-checkbox>
                </v-col>


                <v-col v-if="$store.getters['permissions/checkModule'](54)" cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="AddShowInTableBee"
                      autocomplete="off"
                      :label="$t('generic.lang_displayInTablebee')"
                      required
                  ></v-checkbox>
                </v-col>


                <v-col v-if="$store.getters['permissions/checkModule'](13)" cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="AddShowInLocalBee"
                      autocomplete="off"
                      :label="$t('generic.lang_displayInLocalbee')"
                      required
                  ></v-checkbox>
                </v-col>

                <v-col v-if="$store.getters['permissions/checkModule'](67)" cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="AddShowInKiosk"
                      autocomplete="off"
                      :label="$t('generic.lang_displayInKiosk')"
                      required
                  ></v-checkbox>
                </v-col>

                <v-col v-if="$store.getters['permissions/checkModule'](67)" cols="12" sm="6" md="4" lg="3">
                  <v-checkbox
                      v-model="showImagesInKiosk"
                      autocomplete="off"
                      :label="$t('erp.lang_showGarnishImageInKiosk')"
                      required
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h6>{{ $t('erp.lang_webTranslations') }}:</h6>
                </v-col>
                <v-col class="pt-0 pb-0" col="12" md="6">
                  <v-text-field @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="addTabName_de" outlined
                                :label="$t('generic.lang_germanyName')">
                    <template v-slot:append>
                      <country-flag class="flag" country="DE"/>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" col="12" md="6">
                  <v-text-field @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="addTabName_en" outlined
                                :label="$t('generic.lang_englishName')">
                    <template v-slot:append>
                      <country-flag class="flag" country="GB"/>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" col="12" md="6">
                  <v-text-field @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="addTabName_fr" outlined
                                :label="$t('generic.lang_frenchName')">
                    <template v-slot:append>
                      <country-flag class="flag" country="FR"/>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" col="12" md="6">
                  <v-text-field @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="addTabName_ar" outlined
                                :label="$t('generic.lang_arabicName')">
                    <template v-slot:append>
                      <country-flag class="flag" country="SA"/>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn color="success" :disabled="addTabName.length < 1" @click="addData">{{
                      $t('generic.lang_add')
                    }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<style>
.dataTables_filter {
  display: none;
}
</style>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import Datatable from "../../datatable/Datatable";
import mixin from "../../../mixins/KeyboardMixIns";
import CountryFlag from "vue-country-flag";
import {createNamespacedHelpers} from 'vuex';
import Swatches from 'vue-swatches';


export default {
  components: {
    Datatable,
    CountryFlag,
    Swatches
  },
  mixins: [mixin],
  data() {
    return {
      showImagesInKiosk: false,
      showInKiosk: false,
      showInLocalBee: false,
      showInTableBee: false,
      showInPOS: false,
      AddShowInLocalBee: false,
      AddShowInTableBee: false,
      AddShowInPOS: true,
      AddShowInKiosk: true,
      ENDPOINTS,
      id: null,
      text: "",
      text_en: "",
      text_fr: "",
      text_ar: "",
      position: 0,
      minChoose: 0,
      maxChoose: 0,
      extraLayoutImage: 0,
      addTabName: "",
      addTabName_en: "",
      addTabName_fr: "",
      addTabName_ar: "",
      addTabName_de: "",
      addTabPosition: 0,
      addTabMinChoose: 0,
      addTabMaxChoose: 0,
      addTabExtraLayoutImage: 0,
      search: "",
      color: null,
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('erp.lang_extraTabsZwaenge'), value: "name", sort: "asc"},
        {text: this.$t('generic.lang_sorting'), value: "sort"},
        {text: this.$t('settings.lang_minAuswahl'), value: "minChoose"},
        {text: this.$t('settings.lang_maxAuswahl'), value: "maxChoose"}
      ],
      excelColumns: [

        {
          label: "ID",
          field: 'id',
        },
        {
          label: this.$t('erp.lang_extraTabsZwaenge'),
          field: "name",
        },
        {
          label: this.$t('generic.lang_sorting'),
          field: "sort",
        },
        {
          label: this.$t('settings.lang_minAuswahl'),
          field: "minChoose",
        },
        {
          label: this.$t('settings.lang_maxAuswahl'),
          field: "maxChoose",
        }
      ],
    }
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },
  computed: {
    ...createNamespacedHelpers("settings").mapGetters([
      "getSettingValue"
    ]),
    isTranslationEnabled() {
      return parseInt(this.getSettingValue("enable_translation")) === 1;
    },
  },
  methods: {
    addData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.EXTRATAB.CREATE, {
        addTabName: this.addTabName,
        addTabName_de: this.addTabName_de,
        addTabName_en: this.addTabName_en,
        addTabName_fr: this.addTabName_fr,
        addTabName_ar: this.addTabName_ar,
        addTabPosition: this.addTabPosition,
        addTabMinChoose: this.addTabMinChoose,
        addTabMaxChoose: this.addTabMaxChoose,
        addTabExtraLayoutImage: this.addTabExtraLayoutImage,
        showInPOS: this.AddShowInPOS,
        showInLocalBee: this.AddShowInLocalBee,
        showInTableBee: this.AddShowInTableBee,
        showInKiosk: this.AddShowInKiosk,
        tabColor: this.color,
        showImagesInKiosk: this.showImagesInKiosk,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_extraTab_added'),
            color: "success"
          });

          self.showList();
          self.addTabName = "";
          self.$refs.extraTabDatatable.getDataFromApi();
          self.$refs.extraTabDatatable.resetSelectedRows();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },
    updateData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.EXTRATAB.UPDATE, {
        garnishTabID: this.id,
        addTabName: this.text,
        addTabName_de: this.text_de,
        addTabName_en: this.text_en,
        addTabName_fr: this.text_fr,
        addTabName_ar: this.text_ar,
        addTabPosition: this.position,
        addTabMinChoose: this.minChoose,
        addTabMaxChoose: this.maxChoose,
        addTabExtraLayoutImage: this.extraLayoutImage,
        showInPOS: this.showInPOS,
        showInLocalBee: this.showInLocalBee,
        showInTableBee: this.showInTableBee,
        showInKiosk: this.showInKiosk,
        tabColor: this.color,
        showImagesInKiosk: this.showImagesInKiosk
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          self.resetData();
          self.$refs.extraTabDatatable.getDataFromApi();
          self.$refs.extraTabDatatable.resetSelectedRows();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_deleteExtraTab'),
        text: this.$t('erp.lang_realyDeleteExtraTab'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.ERP.EXTRATAB.DELETE, {
            garnishTabID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_extraTabDeleted'),
                color: "success"
              });

              self.resetData();
              self.$refs.extraTabDatatable.getDataFromApi();
              self.$refs.extraTabDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    entryEdit(entry) {
      let self = this;

      self.axios.post(ENDPOINTS.ERP.EXTRATAB.GET, {
        garnishTabID: entry.id
      }).then((res) => {
        if (res.status === 200) {
          self.id = entry.id;
          self.text = res.data.formfillData.textFields.addTabName;
          self.text_de = res.data.formfillData.textFields.addTabName_de;
          self.text_en = res.data.formfillData.textFields.addTabName_en;
          self.text_fr = res.data.formfillData.textFields.addTabName_fr;
          self.text_ar = res.data.formfillData.textFields.addTabName_ar;
          self.position = res.data.formfillData.textFields.addTabPosition;
          self.minChoose = res.data.formfillData.textFields.addTabMinChoose;
          self.maxChoose = res.data.formfillData.textFields.addTabMaxChoose;
          self.color = res.data.formfillData.textFields.tabColor;
          self.extraLayoutImage = res.data.formfillData.checkBoxData.addTabExtraLayoutImage;
          self.showTable = false;
          self.showUpdate = true;
          this.showInPOS = res.data.formfillData.checkBoxData.showInPOS;
          this.showInLocalBee = res.data.formfillData.checkBoxData.showInLocalBee;
          this.showInTableBee = res.data.formfillData.checkBoxData.showInTableBee;
          this.showInKiosk = res.data.formfillData.checkBoxData.showInKiosk;
          this.showImagesInKiosk = res.data.formfillData.checkBoxData.showImagesInKiosk;
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    entryDelete(entry) {
      this.id = entry.id;

      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },

  },
}
</script>


<style scoped>
.flag {
  position: absolute;
  right: 5px;
  top: 12px;
}
</style>
